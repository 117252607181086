import { LinearProgress, ThemeProvider } from "@mui/material";

import { Suspense, lazy } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { RecordTypeEnum } from "./__generated__/graphql";
import Home from "./components/home";
import GoogleOAuthWrapper from "./context/google";
import { MatomoContextProvider } from "./context/matomo/MatomoContext";
import AppBar from "./pages/MainPages";
import { tokensExist } from "./pages/login/utils";
import { theme } from "./theme";
import { shouldSeeLiveTab } from "./utils";

const LazyTypeRecord = lazy(() => import("./components/weather/TypeRecord"));
const LazyLoginPage = lazy(() => import("./pages/login/index"));
const LazyGrafanaElec = lazy(() => import("./components/Grafana"));
const LazyLiveElectricity = lazy(() => import("./components/LiveElectricity"));

function App() {
  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");
  const isAuthenticated = tokensExist({ accessToken, refreshToken });
  const domain = window.location.hostname.split(".")[0];
  window.document.title = domain.charAt(0).toUpperCase() + domain.slice(1);

  return (
    <GoogleOAuthWrapper>
      <MatomoContextProvider>
        <ThemeProvider theme={theme}>
          <Router>
            <Suspense fallback={<LinearProgress />}>
              {isAuthenticated && <AppBar />}
              <Routes>
                {!isAuthenticated && (
                  <>
                    <Route path="/login" element={<LazyLoginPage />} />
                    <Route path="/" element={<LazyLoginPage />} />
                    <Route path="*" element={<Navigate to={"/"} />} />
                  </>
                )}
                {isAuthenticated && (
                  <>
                    <Route path="/home" element={<Home />} />
                    <Route path="/electricity" element={<LazyGrafanaElec />} />
                    {shouldSeeLiveTab && (
                      <Route
                        path="/live-electricity"
                        element={<LazyLiveElectricity />}
                      />
                    )}
                    <Route
                      path="/temperature"
                      element={
                        <LazyTypeRecord type={RecordTypeEnum.Temperature} />
                      }
                    />
                    <Route
                      path="/humidity"
                      element={
                        <LazyTypeRecord type={RecordTypeEnum.Humidity} />
                      }
                    />
                    <Route path="*" element={<Navigate to={"/home"} />} />
                  </>
                )}
              </Routes>
            </Suspense>
          </Router>
        </ThemeProvider>
      </MatomoContextProvider>
    </GoogleOAuthWrapper>
  );
}

export default App;
