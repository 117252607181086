import { gql } from "../__generated__/";

export const LAST_RECORD = gql(`
  query LastRecord($data: SingleRecordInput!) {
    lastRecord(data: $data) {
      id
      recordedAt
      value
      type
      location
      weather_description
      weather_icon
    }
  }
`);

export const AVERAGE_QUERY = gql(`
  query GetAverage($averageData: AverageInput!) {
    getSingleTypeLocAverage(averageData: $averageData) {
      hours
      averages{
        average
        sunPhase
      }
    }
  }
`);

export const GRAPH_WEATHER_QUERY = gql(`
  query weatherHistoryGraphData($input: WeatherGraphHistoryInput!) {
    weatherHistoryGraphData(input: $input) {
      from
      to
      series{
        data{
          x
          y
        }
        name
        color
        type
      }
    }
  }
`);

export const HOME_QUERY = gql(`
  query home($input: HomeInput!) {
    home(input:  $input) {
      statistics{
        date
        electricity{
          cost
        }
        records{
          location
          value
        }
      }
    }
  }
`);

export const ELECTRICITY_LIVE_QUERY = gql(`
  query electricityLive {
    electricityLive {
        recorded_at
        base
    }
  }
`);

export const ELECTRICITY_LIVE_BY_DATE_QUERY = gql(`
  query electricityLiveByDate {
    electricityLiveByDate {
      date
      total
    }
  }
`);
