/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar.This scalar is serialized to a string in ISO 8601 format and parsed from a string in ISO 8601 format. */
  DateTimeISO: { input: any; output: any; }
};

export type AverageInput = {
  /** The number of hours to go back from now */
  hours: Array<Scalars['Int']['input']>;
  singleRecordInput: SingleRecordInput;
};

export type DailyRecordAverage = {
  __typename?: 'DailyRecordAverage';
  location: LocationEnum;
  value: Scalars['Float']['output'];
};

export type DataGraphXy = {
  __typename?: 'DataGraphXY';
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
};

export type ElectricityDaily = {
  __typename?: 'ElectricityDaily';
  date: Scalars['DateTimeISO']['output'];
  total: Scalars['Float']['output'];
};

/** Electricity live from TIC */
export type ElectricityLive = {
  __typename?: 'ElectricityLive';
  base: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  recorded_at: Scalars['DateTimeISO']['output'];
};

export type ElectricityStatistics = {
  __typename?: 'ElectricityStatistics';
  cost?: Maybe<Scalars['Float']['output']>;
};

/** Type of electricity record */
export enum ElectricityTypeEnum {
  ConsumptionLoadCurve = 'CONSUMPTION_LOAD_CURVE',
  ConsumptionMaxPower = 'CONSUMPTION_MAX_POWER',
  DailyConsumption = 'DAILY_CONSUMPTION'
}

export type GraphHistoryWithRange = {
  __typename?: 'GraphHistoryWithRange';
  from: Scalars['Float']['output'];
  series: Array<RecordGraphHistory>;
  to: Scalars['Float']['output'];
};

export type HomeInput = {
  /** From which timestamp to start */
  from: Scalars['DateTimeISO']['input'];
  /** To which timestamp to end */
  to: Scalars['DateTimeISO']['input'];
};

export type HomeStatistics = {
  __typename?: 'HomeStatistics';
  statistics: Array<MainSingleStatistic>;
};

/** Location where the record has been made */
export enum LocationEnum {
  Bedroom = 'BEDROOM',
  LivingRoom = 'LIVING_ROOM',
  Outside = 'OUTSIDE'
}

export type MainSingleStatistic = {
  __typename?: 'MainSingleStatistic';
  date: Scalars['DateTimeISO']['output'];
  electricity: ElectricityStatistics;
  records: Array<DailyRecordAverage>;
};

export type PaginatedInput = {
  limit?: Scalars['Int']['input'];
  page?: Scalars['Int']['input'];
};

export type Query = {
  __typename?: 'Query';
  /** Retrieves the last record for each type and location */
  allLastRecords: Array<Record>;
  electricityLive: Array<ElectricityLive>;
  electricityLiveByDate: Array<ElectricityDaily>;
  /** Calculates the average value of records for type and location */
  getSingleTypeLocAverage: Array<RecordAverage>;
  /** Retrieves the main statistics for the home */
  home: HomeStatistics;
  /** Retrieves the last record for type and location */
  lastRecord: Array<Record>;
  /** Retrieves a list of records */
  records: Array<Record>;
  /** Retrieves the history of records for type and location */
  weatherHistoryGraphData: GraphHistoryWithRange;
};


export type QueryGetSingleTypeLocAverageArgs = {
  averageData: AverageInput;
};


export type QueryHomeArgs = {
  input: HomeInput;
};


export type QueryLastRecordArgs = {
  data: SingleRecordInput;
};


export type QueryRecordsArgs = {
  data: RecordInput;
};


export type QueryWeatherHistoryGraphDataArgs = {
  input: WeatherGraphHistoryInput;
};

/** Record of temperature or humidity */
export type Record = {
  __typename?: 'Record';
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['ID']['output'];
  location: LocationEnum;
  recordedAt: Scalars['DateTimeISO']['output'];
  sunPhase: SunPhaseEnum;
  type: RecordTypeEnum;
  value: Scalars['Float']['output'];
  weather_description?: Maybe<Scalars['String']['output']>;
  weather_icon?: Maybe<Scalars['String']['output']>;
  weather_id?: Maybe<Scalars['Float']['output']>;
  weather_main?: Maybe<Scalars['String']['output']>;
};

export type RecordAverage = {
  __typename?: 'RecordAverage';
  averages: Array<SingleAverage>;
  hours: Scalars['Float']['output'];
};

export type RecordGraphHistory = {
  __typename?: 'RecordGraphHistory';
  color: Scalars['String']['output'];
  data: Array<DataGraphXy>;
  elecType?: Maybe<ElectricityTypeEnum>;
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
  withCost?: Maybe<Scalars['Boolean']['output']>;
};

/** Retrieves a list of records */
export type RecordInput = {
  id?: InputMaybe<Scalars['Float']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<PaginatedInput>;
  sorted?: InputMaybe<SortedRecordInput>;
  /** Sun visibility filter */
  sunPhase?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** Field to sort records by */
export enum RecordSortField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  RecordedAt = 'RECORDED_AT',
  Type = 'TYPE'
}

/** Humidity or temperature record */
export enum RecordTypeEnum {
  Humidity = 'HUMIDITY',
  Temperature = 'TEMPERATURE'
}

export type SingleAverage = {
  __typename?: 'SingleAverage';
  average: Scalars['Float']['output'];
  sunPhase?: Maybe<SunPhaseEnum>;
};

/** Retrieves the last record for type and location */
export type SingleRecordInput = {
  location?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** Direction of order */
export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Input for sorting records */
export type SortedRecordInput = {
  order?: InputMaybe<SortOrder>;
  sortBy?: InputMaybe<RecordSortField>;
};

/** Sun is visible or not */
export enum SunPhaseEnum {
  NotVisible = 'NOT_VISIBLE',
  Visible = 'VISIBLE'
}

export type WeatherGraphHistoryInput = {
  /** To which timestamp to end the graph */
  max?: InputMaybe<Scalars['Float']['input']>;
  /** From which timestamp to start the graph */
  min?: InputMaybe<Scalars['Float']['input']>;
  /** Sun visibility phase filter */
  sunPhase?: InputMaybe<SunPhaseEnum>;
  /** Type of record */
  type: RecordTypeEnum;
  withElectricity?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LastRecordQueryVariables = Exact<{
  data: SingleRecordInput;
}>;


export type LastRecordQuery = { __typename?: 'Query', lastRecord: Array<{ __typename?: 'Record', id: string, recordedAt: any, value: number, type: RecordTypeEnum, location: LocationEnum, weather_description?: string | null, weather_icon?: string | null }> };

export type GetAverageQueryVariables = Exact<{
  averageData: AverageInput;
}>;


export type GetAverageQuery = { __typename?: 'Query', getSingleTypeLocAverage: Array<{ __typename?: 'RecordAverage', hours: number, averages: Array<{ __typename?: 'SingleAverage', average: number, sunPhase?: SunPhaseEnum | null }> }> };

export type WeatherHistoryGraphDataQueryVariables = Exact<{
  input: WeatherGraphHistoryInput;
}>;


export type WeatherHistoryGraphDataQuery = { __typename?: 'Query', weatherHistoryGraphData: { __typename?: 'GraphHistoryWithRange', from: number, to: number, series: Array<{ __typename?: 'RecordGraphHistory', name: string, color: string, type: string, data: Array<{ __typename?: 'DataGraphXY', x: number, y: number }> }> } };

export type HomeQueryVariables = Exact<{
  input: HomeInput;
}>;


export type HomeQuery = { __typename?: 'Query', home: { __typename?: 'HomeStatistics', statistics: Array<{ __typename?: 'MainSingleStatistic', date: any, electricity: { __typename?: 'ElectricityStatistics', cost?: number | null }, records: Array<{ __typename?: 'DailyRecordAverage', location: LocationEnum, value: number }> }> } };

export type ElectricityLiveQueryVariables = Exact<{ [key: string]: never; }>;


export type ElectricityLiveQuery = { __typename?: 'Query', electricityLive: Array<{ __typename?: 'ElectricityLive', recorded_at: any, base: number }> };

export type ElectricityLiveByDateQueryVariables = Exact<{ [key: string]: never; }>;


export type ElectricityLiveByDateQuery = { __typename?: 'Query', electricityLiveByDate: Array<{ __typename?: 'ElectricityDaily', date: any, total: number }> };


export const LastRecordDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"LastRecord"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"data"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SingleRecordInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"lastRecord"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"data"},"value":{"kind":"Variable","name":{"kind":"Name","value":"data"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"recordedAt"}},{"kind":"Field","name":{"kind":"Name","value":"value"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"location"}},{"kind":"Field","name":{"kind":"Name","value":"weather_description"}},{"kind":"Field","name":{"kind":"Name","value":"weather_icon"}}]}}]}}]} as unknown as DocumentNode<LastRecordQuery, LastRecordQueryVariables>;
export const GetAverageDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetAverage"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"averageData"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AverageInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getSingleTypeLocAverage"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"averageData"},"value":{"kind":"Variable","name":{"kind":"Name","value":"averageData"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"hours"}},{"kind":"Field","name":{"kind":"Name","value":"averages"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"average"}},{"kind":"Field","name":{"kind":"Name","value":"sunPhase"}}]}}]}}]}}]} as unknown as DocumentNode<GetAverageQuery, GetAverageQueryVariables>;
export const WeatherHistoryGraphDataDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"weatherHistoryGraphData"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"WeatherGraphHistoryInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"weatherHistoryGraphData"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"from"}},{"kind":"Field","name":{"kind":"Name","value":"to"}},{"kind":"Field","name":{"kind":"Name","value":"series"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"x"}},{"kind":"Field","name":{"kind":"Name","value":"y"}}]}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"color"}},{"kind":"Field","name":{"kind":"Name","value":"type"}}]}}]}}]}}]} as unknown as DocumentNode<WeatherHistoryGraphDataQuery, WeatherHistoryGraphDataQueryVariables>;
export const HomeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"home"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"HomeInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"home"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"statistics"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"date"}},{"kind":"Field","name":{"kind":"Name","value":"electricity"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"cost"}}]}},{"kind":"Field","name":{"kind":"Name","value":"records"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"location"}},{"kind":"Field","name":{"kind":"Name","value":"value"}}]}}]}}]}}]}}]} as unknown as DocumentNode<HomeQuery, HomeQueryVariables>;
export const ElectricityLiveDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"electricityLive"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"electricityLive"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"recorded_at"}},{"kind":"Field","name":{"kind":"Name","value":"base"}}]}}]}}]} as unknown as DocumentNode<ElectricityLiveQuery, ElectricityLiveQueryVariables>;
export const ElectricityLiveByDateDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"electricityLiveByDate"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"electricityLiveByDate"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"date"}},{"kind":"Field","name":{"kind":"Name","value":"total"}}]}}]}}]} as unknown as DocumentNode<ElectricityLiveByDateQuery, ElectricityLiveByDateQueryVariables>;