import { DomainEnum, getDomain } from "./context/utils";

export const displayElectricityCost = (cost: number) => {
  const euroValue = Math.round(cost / 1000000) / 10;
  return euroValue.toLocaleString() + "€";
};

const domainEnum = getDomain();
export const shouldSeeLiveTab =
  domainEnum === DomainEnum.LOCALHOST || domainEnum === DomainEnum.DOMONTROUGE;
