/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query LastRecord($data: SingleRecordInput!) {\n    lastRecord(data: $data) {\n      id\n      recordedAt\n      value\n      type\n      location\n      weather_description\n      weather_icon\n    }\n  }\n": types.LastRecordDocument,
    "\n  query GetAverage($averageData: AverageInput!) {\n    getSingleTypeLocAverage(averageData: $averageData) {\n      hours\n      averages{\n        average\n        sunPhase\n      }\n    }\n  }\n": types.GetAverageDocument,
    "\n  query weatherHistoryGraphData($input: WeatherGraphHistoryInput!) {\n    weatherHistoryGraphData(input: $input) {\n      from\n      to\n      series{\n        data{\n          x\n          y\n        }\n        name\n        color\n        type\n      }\n    }\n  }\n": types.WeatherHistoryGraphDataDocument,
    "\n  query home($input: HomeInput!) {\n    home(input:  $input) {\n      statistics{\n        date\n        electricity{\n          cost\n        }\n        records{\n          location\n          value\n        }\n      }\n    }\n  }\n": types.HomeDocument,
    "\n  query electricityLive {\n    electricityLive {\n        recorded_at\n        base\n    }\n  }\n": types.ElectricityLiveDocument,
    "\n  query electricityLiveByDate {\n    electricityLiveByDate {\n      date\n      total\n    }\n  }\n": types.ElectricityLiveByDateDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query LastRecord($data: SingleRecordInput!) {\n    lastRecord(data: $data) {\n      id\n      recordedAt\n      value\n      type\n      location\n      weather_description\n      weather_icon\n    }\n  }\n"): (typeof documents)["\n  query LastRecord($data: SingleRecordInput!) {\n    lastRecord(data: $data) {\n      id\n      recordedAt\n      value\n      type\n      location\n      weather_description\n      weather_icon\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetAverage($averageData: AverageInput!) {\n    getSingleTypeLocAverage(averageData: $averageData) {\n      hours\n      averages{\n        average\n        sunPhase\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetAverage($averageData: AverageInput!) {\n    getSingleTypeLocAverage(averageData: $averageData) {\n      hours\n      averages{\n        average\n        sunPhase\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query weatherHistoryGraphData($input: WeatherGraphHistoryInput!) {\n    weatherHistoryGraphData(input: $input) {\n      from\n      to\n      series{\n        data{\n          x\n          y\n        }\n        name\n        color\n        type\n      }\n    }\n  }\n"): (typeof documents)["\n  query weatherHistoryGraphData($input: WeatherGraphHistoryInput!) {\n    weatherHistoryGraphData(input: $input) {\n      from\n      to\n      series{\n        data{\n          x\n          y\n        }\n        name\n        color\n        type\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query home($input: HomeInput!) {\n    home(input:  $input) {\n      statistics{\n        date\n        electricity{\n          cost\n        }\n        records{\n          location\n          value\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query home($input: HomeInput!) {\n    home(input:  $input) {\n      statistics{\n        date\n        electricity{\n          cost\n        }\n        records{\n          location\n          value\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query electricityLive {\n    electricityLive {\n        recorded_at\n        base\n    }\n  }\n"): (typeof documents)["\n  query electricityLive {\n    electricityLive {\n        recorded_at\n        base\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query electricityLiveByDate {\n    electricityLiveByDate {\n      date\n      total\n    }\n  }\n"): (typeof documents)["\n  query electricityLiveByDate {\n    electricityLiveByDate {\n      date\n      total\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;